import * as React from 'react';
import Image from 'next/image';

import { ErrorStrings, GeneralStrings, LoginStrings } from '@kvika/audur-strings';
import Button from '@kvika/audur-button';
import { isMobilePhoneNumberValid, validateSSN } from '@kvika/string-utils';
import { useSearchParams } from 'next/navigation';

import Heading from '../components/common/Heading';
import { Colors } from '../dls/Styleguide';
import { StyledPageImage } from '../components/styledComponents/PageImageStyles';
import {
  StyledAudurLogo,
  StyledLeftContent,
  StyledLoginPage,
  StyledLoginPageContainer,
} from '../styles/PageStyles/LoginPageStyles';
import AuthMethodTabs from '../components/authentication/AuthMethodTabs';
import { AudurModal, TextInfo } from '../components/common';
import { useLogin } from '../hooks/useLogin';
import { LoginIntent, LoginStep } from '../types/Types';

const LoginPage = () => {
  const [headingText, setHeadingText] = React.useState<string>(LoginStrings.ContinueWithEID);
  const [phoneNumber, setPhoneNumber] = React.useState<string>('');
  const [ssn, setSsn] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState<boolean>(false);
  const [hasPendingLoginRequest, setHasPendingLoginRequest] = React.useState<boolean>(false);
  const [isLoggingInWithPhoneNumber, setIsLoggingInWithPhoneNumber] = React.useState<boolean>(true);

  const searchParams = useSearchParams();
  const loginIntent = searchParams.get('user') === LoginIntent.company ? LoginIntent.company : LoginIntent.individual;
  const { onLoginStart, loginState, verificationCode } = useLogin(
    phoneNumber,
    ssn,
    isLoggingInWithPhoneNumber,
    loginIntent
  );

  const getIsLoginButtonDisabled = () => {
    if (isLoggingInWithPhoneNumber) {
      return !isMobilePhoneNumberValid(phoneNumber) || isLoading;
    }
    return !validateSSN(ssn) || isLoading;
  };

  React.useEffect(() => {
    switch (loginState) {
      case LoginStep.Idle:
      case LoginStep.Success:
        setIsLoading(false);
        setHasPendingLoginRequest(false);
        setIsErrorModalOpen(false);
        break;
      case LoginStep.Started:
        setIsLoading(true);
        break;
      case LoginStep.Pending:
        setHasPendingLoginRequest(true);
        break;
      case LoginStep.Error:
        setIsErrorModalOpen(true);
        setIsLoading(false);
        setHasPendingLoginRequest(false);
        break;
      default:
    }
  }, [loginState]);

  return (
    <StyledLoginPage>
      <AudurModal
        isOpen={isErrorModalOpen}
        handleClose={() => setIsErrorModalOpen(false)}
        title={ErrorStrings.AudkenniDescriptionError}
      >
        <TextInfo text={ErrorStrings.LoginTimedOutError} />
      </AudurModal>
      <AudurModal
        isOpen={hasPendingLoginRequest}
        handleClose={() => setHasPendingLoginRequest(false)}
        title={ErrorStrings.AudkenniDescriptionError}
      >
        <TextInfo text={`${ErrorStrings.AlreadyPendinLogin} ${phoneNumber}`} />
      </AudurModal>
      <StyledAudurLogo>
        <Image width={136} height={75} src="/svg/audur_logo.svg" alt="Audur logo" />
      </StyledAudurLogo>
      <StyledLoginPageContainer>
        <StyledLeftContent>
          <Heading text={headingText} textColor={Colors.Beige} />
          <AuthMethodTabs
            onTabChange={() => {
              setHeadingText(
                headingText !== LoginStrings.ContinueWithEID
                  ? LoginStrings.ContinueWithEID
                  : LoginStrings.ContinueWithESIM
              );
              setPhoneNumber('');
              setSsn('');
            }}
            phoneNumber={phoneNumber}
            onChangePhoneNumber={setPhoneNumber}
            ssn={ssn}
            onChangeSSN={(ssn) => setSsn(ssn.replace('-', ''))}
            isLoading={isLoading}
            isLoggingInWithPhoneNumber={isLoggingInWithPhoneNumber}
            setIsLoggingInWithPhoneNumber={setIsLoggingInWithPhoneNumber}
            verificationCode={verificationCode}
            onLoginStart={() => {
              onLoginStart();
            }}
          />
          <Button
            width="100%"
            disabled={getIsLoginButtonDisabled() || isLoading}
            loading={isLoading}
            onClick={onLoginStart}
          >
            {GeneralStrings.Forward}
          </Button>
        </StyledLeftContent>
        <StyledPageImage>
          <Image width={397} height={398} src="/svg/person_working.svg" alt="Person working" priority />
        </StyledPageImage>
      </StyledLoginPageContainer>
    </StyledLoginPage>
  );
};

export default LoginPage;
